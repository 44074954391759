<template>
  <PageContainer>
    <PageTitle>
      {{ trans('PAGE_TITLE:AccountBlocked') }}
    </PageTitle>
    <t-card class="w-full lg:w-420" v-if="userData.isBanned">

    </t-card>
  </PageContainer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import router from "@/router";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";

export default {
  name: 'AccountBlocked',
  data () {
    return {
      email: '',
    }
  },
  components: {
    PageTitle,
    PageContainer,
    Button
  },
  computed: {
		...mapGetters('dictionaries', ['postCategoriesData']),
    ...mapGetters('account', ['resendVerificationLoading', 'resendLinkDisable', 'userData'])
  },
  methods: {
	  goToRoute() {
		  let contact = this.postCategoriesData.find(el => el.slug === 'contact');
	    router.push({name: 'post-show', params: {postCategory: contact.slug, slug: contact.onlyPostSlug}, query: {banned: true}})
	  }
  },
  mounted() {
		if(!this.userData.isBanned) {
			router.push({name: 'homepage'})
		}
  }
}
</script>

<style lang="scss">
</style>
